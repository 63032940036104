import { BrandlogoArray } from "@/DummyData";
import { BoxShowdowLeft, BoxShowdowRight, MarqueeContainerMb, MarqueeDiv, OrderInner, } from "@/StyledComponents/HomeStyled";
import Image from "next/image";
export default function MarqueeLogo(props) {
  return (
    <OrderInner>
      <BoxShowdowLeft className="drk-shawdow" />
      <BoxShowdowRight className="drk-shawdow" />
      {BrandlogoArray?.map((item) => (
        <MarqueeContainerMb key={item.id}>
          <MarqueeDiv className="rfm-marquee-container ">
            <span>
              <Image
                className="ml-[60px] md-[60px] lg:ml-[95px]"
                src={item.url}
                width={4691}
                height={44}
                alt={item.alt}
                loading="lazy"
              />
            </span>
          </MarqueeDiv>
        </MarqueeContainerMb>
      ))}
    </OrderInner>
  );
}
